// some misc styles
.element--center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.menu {
	background-color: #f3f3f3;
	color: #333;
	border-radius: 2px;
	padding: 1px 5px;
}

.text-center {
	text-align: center;
}

.pull-right {
	float: right;
}

.draft {
    color: #999 !important;
}

.bighand {
	font-size: 3rem;
	margin-top: .3rem;
}

.botton-right-text {
	position: absolute;
	bottom: 8px;
	right: 8px;
	color: #f3f3f3;
	font-size: 16px;
}
.rides-img {
	position: relative;
	text-align: center;
}