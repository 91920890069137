.sidebar {
  background-image: linear-gradient(
      rgba(35, 48, 87, 0.3) 0%,
      rgba(39, 69, 97, 0.3) 12%,
      rgba(33, 65, 74, 0.8) 39%,
      rgba(2, 3, 3, 0.6) 74%,
      rgb(18, 20, 20) 96%
    ),
    url("/site-static/sidebar-background.jpg");
  padding: 1rem 1rem;
  text-align: center;
  p {
    color: $gray-0;
  }
  a {
    color: $gray-0;
    border: none;
    &:hover {
      color: $link-color;
    }
    &:focus {
      color: $link-color;
    }
  }
  .sidebar-about {
    text-align: center;
  }
  .author-image {
    display: block;
    margin-top: 0px;
  }
}

.sidebar-nav {
  text-align: center;
  list-style: none;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-left: 0;
}

.sidebar-nav-item {
  display: block;
  line-height: 1.75;
  .active {
    font-weight: bold;
  }
}
.site__title {
  font-size: $site__title-font-size;
  margin-bottom: 0.5rem;
  a:hover {
    border: none;
  }
}

.site__description {
  font-size: 1.5rem;
  font-weight: 500;
}

.social {
  text-align: center;
  a {
    padding: 0 4px;
    @include link-no-decoration();
  }
}

.img--circle {
  border-radius: 90%;
}

.img--headshot {
  height: 180px;
  width: 180px;
}

.img--caption {
  font-style: italic;
}

%small-center-text {
  font-size: $font-scale-dot7;
  line-height: 1.1rem;
  text-align: center;
}

.copyright {
  padding-top: 1rem;
  @extend %small-center-text;
}
.builtwith {
  padding-top: 0.2rem;
  @extend %small-center-text;
}
